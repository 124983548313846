<template>
  <div id="reportList">

    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Pull List Mappings</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: '/build-pull-list' }">Pull List Mapping</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row v-loading="loading" element-loading-spinner="particle-loader">
      <el-button size="mini" @click="postMapping" style="margin-bottom: 10px;">Create Mapping</el-button>
      <el-col :span="24">
        <v-client-table v-if="!loading" class="hiaTable" ref="reportList" id="reportList" :data="tableData" :columns="columns" :options="options">
          <template slot="id" slot-scope="props">
            <router-link :to="`/reviews/pulllist-builds/${props.row.id}`">{{props.row.id}}</router-link>
          </template>
          <template slot="projectTypeId" slot-scope="props">
            {{GET_VALUEFROMKEY('allProjectTypes', props.row.projectTypeId)}}
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i size="mini" type="button" class="el-icon-close delete-button" @click="deleteMapping(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'

  export default {
    name: 'reportList',
    data() {
      return {
        loading: false,
        tableData: [],
        columns: ['id', 'projectTypeId', 'mappingName', 'delete'],
        options: {
          filterByColumn: true,
          orderBy: {
            column: 'reportType',
            ascending: true
          },
          headings: {
            clientNumber: 'Client Number',
            mappingType: 'Mapping Type',
            fileName: 'File Name',
            delete: ''
          },
          perPage: 50,
          sortIcon: { is: 'none' },
          texts: {
            filter: '',
            filterBy: '...'
          }
        }
      }
    },
    created: function () {
      this.initialize(this.$route)
    },
    methods: {
      ...mapActions('pulllists/', ['GET_PULLLIST_BUILDS', 'GET_TARGET_TEMPLATES', 'POST_PULLLIST_BUILD', 'DELETE_PULLLIST_BUILD']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async initialize(route) {
        this.loading = true
        await Promise.all([this.GET_PULLLIST_BUILDS(), this.GET_DROPDOWNS(['allProjectTypes'])])
        this.tableData = this.pullListBuilds
        this.loading = false
      },
      async postMapping() {
        const payload = {
          MappingName: 'TBD',
          PullListBuild: JSON.stringify({
            dxs: false,
            pxs: false,
            cpts: false,
            poas: false,
            modifiers: false,
            fileName: null,
            recordRowStyle: 'Single',
            clientNumber: null,
            patientNumberField: null,
            dischargeDateField: null,
            stringDistance: 3,
            numberOfPreviewRows: 25,
            mapping: [],
            sourcePreview: []
          })
        }

        const response = await this.POST_PULLLIST_BUILD(payload)
        this.$router.push(`/reviews/pulllist-builds/${response.data.id}`)
      },
      async deleteMapping(row) {
        console.log(row)
        await this.$confirm(`Permanently delete mapping ${row.mappingName}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        await this.DELETE_PULLLIST_BUILD(row.id)
        // await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/pullListBuild/${row.id}`)
        this.initialize()
      }
    },
    computed: {
      ...mapState('pulllists/', ['pullListBuilds', 'targetTemplates']),
      ...mapGetters('dropdowns/', ['GET_VALUEFROMKEY'])
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .reportList {
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .reportListCheck {
    float: left;
    height: 15px;
    width: 15px;
    margin: 4px 0px 0px 0px;
    color: #48576a;
  }

  .reportListCheckOuter {
    width: 25px;
  }

  .reportListMeta {
    margin: 0px 0px 0px 20px;
  }

  .reportListMeta p {
    font-size: 12px;
  }

  .reportList li {
    width: 25%;
    min-height: 80px;
    padding: 0px 5px 0px 5px;
    display: inline-block;
    vertical-align: top;
  }
</style>

<style>
  .el-card__header {
    padding: 5px 10px;
    border-bottom: 1px solid #d1dbe5;
    box-sizing: border-box;
  }
</style>